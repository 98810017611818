import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
    public appInsights: any;
    public reactPlugin: any;

    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    initialize(reactPluginConfig:any, disableTelemetry:boolean) {
        // @ts-ignore
        let INSTRUMENTATION_KEY: string = '';

        if (process.env.NODE_ENV !== 'development') {
            INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY || '';
        }

        if (!INSTRUMENTATION_KEY) {
            console.warn("Instrumentation key is not set. Telemetry will be disabled.");
            disableTelemetry = true;
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: INSTRUMENTATION_KEY,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: reactPluginConfig
                },
                disableTelemetry: disableTelemetry
            }
        });
        this.appInsights.loadAppInsights();
    }
}

export let ai = new TelemetryService();
